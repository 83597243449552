import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/SchoolProgram/2024/Nobagday/1.jpg';
// import p2 from 'assests/Photos/SchoolProgram/2024/Nobagday/2.jpg';
// import p3 from 'assests/Photos/SchoolProgram/2024/Nobagday/3.jpg';
// import p4 from 'assests/Photos/SchoolProgram/2024/Nobagday/4.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';
import Sidebar2024 from '../SchoolProgram2023/components/Sidebar2024';
import Archive2022 from '../SchoolProgram2023/components/Archive2022';


const MariaMontessoriDay2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/school-programs/MARIA/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/school-programs/MARIA/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/school-programs/MARIA/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/school-programs/MARIA/4.webp`;
  const p5 = `${config.base_image_url}/home/events-activities/school-programs/MARIA/5.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        } ,
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        }
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                DR. MARIA MONTESSORI DAY
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                 Class: Pre-Primary      Date: 30 August 2024                                                                                  Date: 3 August 2024
                                </Typography>
                                {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “If you believe in yourself and have dedication and determination and never quit, you'll be a winner. The price of victory is high but so are the rewards.”

                                <br />
                            </Typography> */}

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                The Pre-Primary department celebrated Dr. Maria Montessori's birthday on August 30, 2024, to honour her pioneering contributions to early childhood education. The event began with a talk on her life, highlighting her child-centered educational approach and global influence. A video featuring one of her great-grandchildren emphasized how Montessori education has flourished worldwide, including in India. 
                                    <br></br>
                                    Teachers presented an overview of Montessori's methodology, showcasing how her scientifically designed materials support early learning. They also demonstrated key concepts from subjects like Practical Life, Sensorial, Language, Arithmetic, Geography, and Botany, with a display of Montessori materials for the curious minds.
                                    <br></br>
                                    The event concluded with a song of gratitude from the Montessori teachers, acknowledging Dr. Montessori’s enduring impact on education. The Montessori Resource Room was then officially opened, allowing students from various classes to engage in hands-on activities with Montessori materials, deepening their learning experience.
                                </Typography>
                            </Box>
                            {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “ PEOPLE FORGET FACTS, BUT THEY REMEMBER STORIES ”
                                <br />
                            </Typography> */}

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2024/>
                        </Box> 
                        <Box marginBottom={4}>
                            <Archive2022/>
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default MariaMontessoriDay2024;